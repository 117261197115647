import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const [oldVis, setOldVis] = useState("");
  const [oldUsername, newUsername] = useState();
  const [oldPassword, newPassword] = useState();
  const navigate = useNavigate();
  const HandleFormSubmit = (e) => {
    e.preventDefault();
    if(Boolean(oldUsername) && Boolean(oldPassword)){
      const formdata = new FormData();
      formdata.append("username", oldUsername);
      formdata.append("password", oldPassword);
        axios.post("https://adigrp.co.in/macpro/auth.php",formdata).then((res)=>{
          console.log(res.data);
          if(res.data.status === true){
            // alert(res.data.message);
            navigate("/auth", { replace: false, state: { allowed : 1 } });
          } else {
            alert(res.data.message);
          }

        })

            

    }else{
      alert("Please fill in all fields!")
    }
  }

  return (
   <div>
<div className="background" style={{"visibility":oldVis}} >
  <div className="window glass active" style={{ maxWidth: 320 }}>
    <div className="title-bar">
      <div className="title-bar-text">Login To Authentication Utility</div>
      <div className="title-bar-controls">
        {/* <button aria-label="Minimize" /> */}
        <button aria-label="Close" onClick={()=>{ setOldVis("hidden") }} />
      </div> 
    </div>
    <div className="window-body has-space">
    
        <form onSubmit={HandleFormSubmit}>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src="logo512.png" height={50} />
      <div className="field-row">
  <label htmlFor="username">Username</label>
  <input id="username" onChange={(e) => { newUsername(e.target.value); }} type="text" />
  
</div>
<div className="field-row">
  <label htmlFor="password">Password</label>
  <input id="password" onChange={(e) => { newPassword(e.target.value); }} type="password" />
</div>
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button type="submit">Login</button>
</form>
      
    </div>
  </div>
</div>


   </div>
  );
}

export default App;
