import axios from "axios";
import { useEffect, useState } from "react";

const ClientAuthTable = () => {

    const [oldMachineList, setOldMachineList] = useState([]);
    const MachineList = () => { 
        const formdata = new FormData();
        formdata.append("apikey", "allowedserveauth2023");
        axios.post("https://adigrp.co.in/macpro/machinelist.php", formdata).then((res)=>{
            console.log(res.data);
            setOldMachineList(res.data);
        });
    }
    useEffect(() => {
        MachineList();
    }, []);

    const UpdateStatus = (sno, valid) => {
        const formdata = new FormData();
        formdata.append("apikey", "allowedserveauth2023");
        formdata.append("sno", sno);
        formdata.append("valid", valid);
        axios.post("https://adigrp.co.in/macpro/upallow.php", formdata).then((res)=>{
            console.log(res.data);
            MachineList();
        }
        ).catch((err)=>{console.log(err);});

    }

    
    return(<>
    
   
<table border={1} bgcolor="white" style={{borderColor : "black"}}>
    <thead>
        <tr>
            <th>Comapny Name</th>
            <th>Contact Person</th>
            <th>Mobile Number</th>
            <th>Contact Email</th>
            <th>Contact Address</th>
            <th>MAC Address</th>
            <th>Is Valid</th>
            <th>Date And Time</th>
            
        </tr>
    </thead>
    <tbody>
        {oldMachineList ? oldMachineList.map((item, index) => {
            return (<tr key={index}>
                <td>{item.company_name}</td>
                <td>{item.conatct_person}</td>
                <td>{item.contact_no}</td>
                <td>{item.contact_email}</td>
                <td>{item.conatct_address}</td>
                <td>{item.macpro}</td>
                <td>{item.valid ? item.valid === "1" ? <button onClick={()=> { UpdateStatus(item.sno, "0") }}>Allowed</button>: <button onClick={()=> { UpdateStatus(item.sno, "1") }}>Denied</button> : <></>}</td>
                <td>{item.datetime}</td>

            </tr>)
        
        }) : <tr><td colSpan={8}>No Data Found</td></tr>}
    </tbody>
</table>

    </>);
}
export default ClientAuthTable;